﻿"use strict";

class SilentLogin {
  constructor() {
    this.instance = null;
    window.addEventListener("message", this.silentLoginHandler);
    console.log("SilentLogin - constructor");
  }

  silentLoginHandler = (e) => {
    if (this.instance && e.data && e.data.source === 'bff-silent-login' && e.data.isLoggedIn) {
      console.log("SilentLogin - silentLoginHandler - isLoggedIn");
      document.querySelector('#bff-silent-login').src = null;
      let _ = this.instance.invokeMethod("SilentLoginSuccess", e);
    }
  };

  initializeSilentLogin = (instance) => {
    console.log("SilentLogin - initializeSilentLogin");
    this.instance = instance;
    document.querySelector('#bff-silent-login').src = '/bff/silent-login';
  };

}

const silentLogin = new SilentLogin();

window.SilentLogin = silentLogin;

if (typeof globalThis === "object")
  globalThis.SilentLogin = silentLogin;
